@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap'); 

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* Tooltip container1 */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  line-height: 24px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container1 */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltiptext {
  width: 160px;
  bottom: 100%;
  padding:5px;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}
.bgblur {
  position: relative;

}
.bgrectangle{
  position: relative;
}
.bgrectangle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/Home/Rectangle.png");
  background-size:contain ;

  
  z-index: -1; /* Send it behind the content */
}


.gradient-text {
  background: linear-gradient(135deg, #f789f4 0%, #b6eeff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-stroke{
  -webkit-text-stroke: 0.5px  #252332;
}
.bgblur::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/gradientblur.svg");
  background-size:cover ;
  background-position: center;
  filter: blur(10px); /* Apply the blur effect */
  z-index: -1; /* Send it behind the content */
}
.bgblur1{
  position: relative;
}
.z-index{
  z-index: 10;
}
.bgblur1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/gradientblur.svg");
  background-size:cover ;
  background-position: bottom;
  filter: blur(10px); /* Apply the blur effect */
  z-index: -1; /* Send it behind the content */
}
.handlee-regular {
  font-family: "Handlee", cursive;
  font-weight: 400;
  font-style: normal;
}
@media screen and (max-width: 480px) {
 .bgrectangle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/Home/Rectangle.png");
  background-size:cover ;

  
  z-index: -1; /* Send it behind the content */
}
}

.bgwhyus{
  background-image: url("./assets/About/about-contact.jpg");

  background-size:cover ;
  background-position: center;
}
.londonicon{
  background-image: url("./assets/Home/londonicons.png");

  background-size:contain ;
  background-position:top;
  background-repeat: no-repeat;
}
.bgcontactus{
  background-image: url("./assets/About/contactformpic.png");

  background-size:cover ;
  background-position: center;
   }
   .bgtestimonial{
    background-image: url("./assets/Home/recircle.png");
  
    background-size:cover ;
    background-position: center;
     }

     .bgcircles{
      background-image: url("./assets/Home/Circle.png");
    
      background-size:cover ;
      background-position: center;
       }
.rounded-bg {
 border-radius:50%;
}