.testimonial {
	width: 520px;
    min-height: 260px;
	max-width: 100%;
	background: #C8C6EB;
	padding: 4em 3em;
	display: flex;
	align-items: flex-end;
	position: relative;
	box-shadow: 0 2px 2px hsl(0deg 0% 0% / .075), 
	            0 3px 3px hsl(0deg 0% 0% / .075), 
	            0 5px 5px hsl(0deg 0% 0% / .075), 
	            0 9px 9px hsl(0deg 0% 0% / .075), 
	            0 17px 17px hsl(0deg 0% 0% / .075);
	position: relative;
}

.testimonial:after {
	content: "";
	
	border-radius: 50px;
	width: 85%;
	height: 120%;
	position: absolute;
	z-index: -1;
	left: 1.5em;
	top: -2em;
}



.testimonial .quote {
	position: absolute;
	font-size: 3em;
	width: 40px;
	height: 40px;
	background: #a09cdc;
	color: #fff;
	text-align: center;
	line-height: 1.25;
}

.testimonial .quote.open {
	top: 0;
	left: 0;
}

.testimonial .quote.close {
	bottom: 0;
	right: 0;
}

.testimonial p {
	width: 70%;
	display: inline-block;

	font-weight: me;
	font-size: 16px;
}

.testimonial .source {
	width: 40%;
	height: 100%;
	position: relative;
}

.testimonial .source span {
	display: inline-block;
	font-weight: bold;
	font-size: 1.15em;
	position: relative;
	margin-left: 1rem;
	text-align: right;
    margin-top: 80px;
}

.testimonial .source span:before {
	content: "\2014";
	display: inline;
	margin-right: 5px;
}

.testimonial .image {
	transform: rotate(-5deg);
	position: absolute;
	top: 0.5em;
	right: 1.5em;
}

.testimonial .image img {
	border: 10px solid #fff;
	margin: 0;
	padding: 0;
}

.testimonial .image .clip {
	border: 2px solid #222;
	border-right: none;
	height: 75px;
	width: 20px;
	position: absolute;
	right: 30%;
	top: -15%;
	border-radius: 25px;
}

.testimonial .image .clip:before {
	content: "";
	position: absolute;
	top: -1px;
	right: 0;
	height: 10px;
	width: 16px;
	border: 2px solid #222;
	border-bottom: none;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	z-index: 99;
}

.testimonial .image .clip:after {
	content: "";
	position: absolute;
	bottom: -1px;
	right: 0;
	height: 40px;
	width: 16px;
	border: 2px solid #222;
	border-top: none;
	border-bottom-left-radius: 25px;
	border-bottom-right-radius: 25px;
	z-index: 99;
}

@media (max-width: 600px) {
    .testimonial {
		
		min-height: 260px;
		width: 100%;
		background: #C8C6EB;
	
		display: flex;
		align-items: flex-end;
		position: relative;
		box-shadow: 0 2px 2px hsl(0deg 0% 0% / .075), 
					0 3px 3px hsl(0deg 0% 0% / .075), 
					0 5px 5px hsl(0deg 0% 0% / .075), 
					0 9px 9px hsl(0deg 0% 0% / .075), 
					0 17px 17px hsl(0deg 0% 0% / .075);
		position: relative;
	}
	.testimonial p{
		width: 250px;
	}
	.testimonial .source span {
		display: inline-block;
		font-weight: bold;
		font-size: 0.8rem;
		position: relative;
		margin-left: 1rem;
		text-align: right;
		margin-top: 60px;
	}
	.testimonial .image .clip {
		border: 2px solid #222;
		border-right: none;
		height: 55px;
		width: 20px;
		position: absolute;
		right: 10%;
		top: -15%;
		border-radius: 25px;
	}
	.testimonial {
		width: 520px;
		min-height: 260px;
		max-width: 100%;
		background: #C8C6EB;
		padding: 4em 3em;
		display: flex;
		flex-direction: column-reverse;
		align-items: flex-start;
		position: relative;
		box-shadow: 0 2px 2px hsl(0deg 0% 0% / .075), 
					0 3px 3px hsl(0deg 0% 0% / .075), 
					0 5px 5px hsl(0deg 0% 0% / .075), 
					0 9px 9px hsl(0deg 0% 0% / .075), 
					0 17px 17px hsl(0deg 0% 0% / .075);
		position: relative;
	}
  }