.container1 {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  
  .container1 .box {
    position: relative;
    width: 250px;
    height: 250px;
    margin: 4rem;
  }
  
  .container1 .box:hover .imgBox {
    transform: translate(-2.8rem, -2.8rem);
    transition: all 0.8s ease-in-out 0.2s; /* Smooth transition with delay */
  }
  
  .container1 .box:hover .content {
    transform: translate(2.8rem, 2.8rem);
    transition: all 0.8s ease-in-out 0.2s; /* Smooth transition with delay */
  }
  
  .imgBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    
    z-index: 2;
    transition: all 0.8s ease-in-out 0.2s; /* Smooth transition with delay */
  }
  
  .imgBox img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    cursor: pointer;
    resize: both;
  }
  
  .content {
    position: absolute;
    border-radius: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    background-color: #a09cdc;
    z-index: 1;
    align-items: flex-end;
    text-align: center;
    transition: 0.5s ease-in-out;
  }
  
  .content h2 {
    display: block;
    font-size: 20px;
    color: white;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 1px;
  }
  
  .content span {
    color: white;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
  }
  
  @media (max-width: 600px) {
    .container1 .box {
      position: relative;
      width: 200px;
      height: 200px;
      margin: 4rem;
    }
    
  
    .container1 .box:hover .content {
      transform: translate(0, 3.5rem);
    }
    .container1 .box:hover .imgBox {
      transform: translate(0, -3.5rem);
    }
    .container1{ justify-content: center;
      align-items: center;}
    .container1 .box{
      position: relative;
    }
    .content{
      position: relative;
    }
  }
  /*# sourceMappingURL=main.css.map */