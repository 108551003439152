.container1 {
    position : relative;
    width : 380px;
    display : flex;
    align-items : center;
    justify-content : center;
     flex-wrap: wrap;
   cursor: pointer;
   
  }
  
  .container1 .card {
    position: relative;
    max-width : 300px;
    height : 200px;  
    margin : 20px 10px;
    padding : 20px 15px;
    
    display : flex;
    align-items: center;
    flex-direction : column;
    
    transition : 0.3s ease-in-out;
    border-radius : 15px;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .container1 .card:hover {
    height : 210px;    
  }
  
  
  .container1 .card .image {
    position : relative;
    width : 270px;
    height : 180px;
    top:0px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
    align-items: center;
   
    z-index : 1;
  }
  
  .container1 .card:hover .image {
    position : relative;
    width : 270px;
    height : 180px;
    top : -30%;
    padding-left: 4px;
    padding-right: 4px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow : 0 5px 20px rgba(0,0,0,0.2);
    z-index : 1;
  }
  
  .container1 .card .image img {
    max-width : 100%;
    border-radius : 15px;
  }
  
  .container1 .card .content {
    position : relative;
    top : -80px;
    padding : 10px 15px;
    text-align : center;
    visibility : hidden;
    opacity : 0;
    transition : 0.3s ease-in-out;
      
  }
  
  .container1 .card:hover .content {
     margin-top : 50px;
     visibility : visible;
     opacity : 1;
     transition-delay: 0.2s;
    
  }